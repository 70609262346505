<template>
    <div>
        <h2>Login</h2>
        <form v-on:submit.prevent="submitForm">
            <p v-if="error" class="error">{{message??this.$store.state.message}}</p>
            <div>
                <label > Email <br>
                    <input type="email" name="email" required v-model="form.email">
                </label>
            </div>
            <br>
            <div>
                <label > Password <br>
                    <input type="password" name="password"  required v-model="form.password">
                </label>
            </div>
            <br>
            <button type="submit">Login</button>
        </form>
    </div>
</template>

<script>


    export default {
        name: 'login',
        data() {
            return {
                error: false,
                formSubmit: true,
                form: {},
                message: 'login'
            }
        },
        methods: {
            submitForm(){
                this.error = false;
                if(!this.validateEmail(this.form.email)) {
                    this.error= true;
                    this.message = 'email not validate';
                    this.error = true;
                }
                if(this.form.password.length <3) {
                    this.error= true;
                    this.message = 'Password must more 3 chars';

                    this.error = true;
                }
                if(!this.error) {
                    console.log('this.form', this.form);
                    let formData=new FormData();

                    console.log('formData', formData);

                    Object.keys(this.form).forEach(key => {
                        console.log('key', key);
                        console.log('this.form[key]', this.form[key]);

                        formData.append(key, this.form[key])
                    });
                    console.log('formData',formData);
                    this.$store.dispatch('fetchLogin', JSON.stringify(this.form));
                    // this.$store.dispatch('fetchLogin', (this.form));
                    if(this.$store.state.error) {
                        this.error= true;
                        this.message=this.$store.state.message;
                    }
                }
            },

            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },

            login: function () {
                let email = this.email
                let password = this.password
                this.$store.dispatch('login', { email, password })
                    .then(() => this.$router.push('/'))
                    .catch(err => console.log(err))
            }
        },


    }
</script>
import {createStore} from 'vuex'

// import Vue from 'vue'
// import Vuex from 'vuex'

import axiosInstance from '@/api/index'
import {
    LOGIN,
    USERS,
    USER,
} from '@/api/routes'
import axios from "axios";

export default createStore({
    state: {
        login: false,
        token: '',
        users: {},
        user: {},
        userId: '',
        error: false,
        message:'',
    },
    mutations: {
        setToken(state, {token}) {
            state.token = token;
        },
        setUsers(state, {users}) {
            state.users = users;
        },
        setUserId(state, id) {
            state.userId = id;
        },
        setUser(state, user) {
            state.user = user;
        },
        setError(state, {error}) {
            state.error = error;
        },
        setMessage(state, {message}) {
            state.message = message;
        },

    },
    actions: {
        fetchLogin({commit}, loginData) {
            console.log('fetchLogin',loginData );

            return axiosInstance
                .post(LOGIN(loginData))
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        if (res.data.status === 'success') {
                            commit('setToken', {token: res.data.data})
                        } else {
                            console.log('setError', res.data.message);

                            commit('setError', {error:true});
                            commit('setMessage', {message:res.data.message});
                        }
                    } else {
                        console.log('res.status ALARM');

                    }
                })
                .catch(err => console.log('error', err));
        },

        fetchUsers({commit}) {
            return axiosInstance
                .get(USERS())
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        if (res.data.status === 'success') {
                            commit('setUsers', {users: res.data.data})
                        } else {
                            commit('setError', {error: res.data.message})
                        }
                    }
                })
                .catch(err => console.log('error', err));
        },
        fetchUser({commit}, userId) {
            console.log('fetchUser userId', userId);
            return axiosInstance
                .get(USER(userId))
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 'success') {
                            commit('setUser', {user: res.data.data})
                        } else {
                            commit('setError', {error: res.data.message})
                        }
                    }
                })
                .catch(err => console.log('error', err));
        },
    },
    getters: {
        error: state => state.error,
    },
    modules: {},


})

export const $axios = axios.create();



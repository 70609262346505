import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Dashboard.vue'),
    children: [{
      path: '',
      component: () => import( '../components/User/index.vue'),
    },
      {
      path: 'user/:id',
        name: 'UserEdit',
        component:  () => import( '../components/User/edit.vue'),
    }],

  },



  {
    path: '/character',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
